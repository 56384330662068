import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ClearIcon from "@mui/icons-material/Clear";
import DynamicFormOutlinedIcon from "@mui/icons-material/DynamicFormOutlined";
import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  FormControl,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import type { GridEventListener } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import type { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { debounce, uniqBy } from "lodash"; // Import debounce from lodash
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import NotAllowedHereCard from "../@rooster/NotAllowedHereCard";
import type {
  Tag,
  TagCreateInput,
  TagUpdateInput
} from "../../@generated/graphql";
import ChangeLoggerButton from "../entityChangeLog/entityChangeLog.button.component";
import { ARCHIVE_MANY_TAGS, GET_TAGS } from "./services/tags.gql";
import TagEdit from "./tag.edit.component";
import { useMyPermissions } from "../../hooks/usePermissions";

function TagsList() {
  const { t } = useTranslation();
  const [filterParams, setFilterParams] = useState<{
    page: number;
    perPage: number;
    searchTerm: string;
    type: string[];
    archived: boolean | null;
  }>({
    page: 1,
    perPage: 25,
    searchTerm: "",
    type: [],
    archived: false
  });

  const { canUserSomeAction, hasClientFeature } = useMyPermissions();

  const apolloClient = useApolloClient();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState<
    TagUpdateInput | TagCreateInput | undefined
  >();

  const { loading, data } = useQuery(GET_TAGS, {
    variables: {
      filter: {
        ...filterParams,
        searchTerm:
          filterParams?.searchTerm?.length < 2 ? "" : filterParams?.searchTerm
      }
    }
  });

  const [archiveManyTags, { loading: deleteProcessing, error: deleteError }] =
    useMutation(ARCHIVE_MANY_TAGS, {
      refetchQueries: [GET_TAGS],
      awaitRefetchQueries: true,
      onCompleted: () => {
        apolloClient.cache.evict({ fieldName: "tags" });
        apolloClient.cache.gc();
      }
    });

  const authUser = useSelector(
    (state: any) => (state?.sessionState?.authUser as Record<string, any>) ?? {}
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setFilterParams({
      ...filterParams,
      searchTerm
    });
  };

  const handleArchive = (archive: boolean) => {
    archiveManyTags({
      variables: {
        data: {
          tagsData:
            data?.tags?.tags
              .filter((tag: Tag) => rowSelectionModel.includes(tag.id))
              .map((tag: Tag) => ({ id: tag.id, name: tag.name })) || [],
          archive
        }
      }
    }).then(() => setRowSelectionModel([]));
  };

  const onCreateNew = () => {
    const newTag: TagCreateInput = {
      clientId: authUser?.client as string,
      name: "",
      type: ["INTERVIEWER"],
      createdBy: authUser?.id ?? authUser.uid,
      archived: false
    };
    setSelectedTag(newTag);
    setDrawerOpen(true);
  };

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    //setMessage(`Movie "${params.row.title}" clicked`);
    setSelectedTag(params.row as TagUpdateInput);
    setDrawerOpen(true);
  };
  const handleClearClick = () => {
    setFilterParams({
      ...filterParams,
      searchTerm: ""
    });
  };
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

  const columns = [
    {
      field: "name",
      headerName: t("ROOSTER.COMMON.NAME", {
        defaultValue: "Name"
      }),
      flex: 2,
      sortable: false
    },
    {
      field: "type",
      headerName: t("ROOSTER.COMMON.TYPE", { defaultValue: "Type" }),
      flex: 2,
      sortable: false
    },
    {
      field: "createdAt",
      headerName: t("ROOSTER.COMMON.CREATED-ON", {
        defaultValue: "Created On"
      }),
      flex: 2,
      sortable: false,
      valueFormatter: ({ value }) => {
        return new Date(value).toLocaleDateString();
      }
    },
    {
      field: "updatedAt",
      headerName: t("ROOSTER.COMMON.UPDATED-ON", {
        defaultValue: "Updated On"
      }),
      flex: 2,
      sortable: false,
      valueFormatter: ({ value }) => {
        if (!value) return null;
        return new Date(value).toLocaleDateString();
      }
    },
    {
      field: "archived",
      headerName: t("ROOSTER.JOB-APPLICATION.ARCHIVED", {
        defaultValue: "Archived"
      }),
      flex: 2,
      sortable: false,
      valueFormatter: ({ value }) => {
        return value ? "Yes" : "No";
      }
    }
  ];

  const [actionsMenuEl, setActionMenuEl] = React.useState<null | HTMLElement>(
    null
  );
  const handleActionsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setActionMenuEl(event.currentTarget);
  };
  const handleActionsMenuClose = () => {
    setActionMenuEl(null);
  };

  if (!hasClientFeature("tags")) {
    return <NotAllowedHereCard />;
  }

  return (
    <Box m={2}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Stack justifyContent="space-between" gutterBottom direction="column">
          <Typography variant={"h4"} gutterBottom color={"primary"}>
            {t("ROOSTER.COMMON.TAGS", {
              defaultValue: "Tags"
            })}
          </Typography>
          <Typography variant="body2" gutterBottom color={"secondary"}>
            {t("ROOSTER.COMMON.TAGS-ONELINER", {
              defaultValue:
                "Assign tags to interviewers to group them by key attributes for easier manual scheduling."
            })}
          </Typography>
        </Stack>

        <Box>
          <ChangeLoggerButton
            buttonProps={{
              color: "primary",
              "aria-label": "change-log",
              id: "change-log-button",
              "aria-haspopup": "true"
            }}
            entityName="tags"
          />
          {canUserSomeAction({
            actions: ["ARCHIVE_TAGS", "UNARCHIVE_TAGS"],
            resource: "tags"
          }) && (
            <Tooltip title="Actions">
              <IconButton
                color="primary"
                sx={{ mr: 1 }}
                aria-label="action"
                id="actions-button"
                aria-haspopup="true"
                onClick={handleActionsMenuClick}
                disabled={rowSelectionModel?.length === 0}
              >
                <DynamicFormOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
          <Menu
            anchorEl={actionsMenuEl}
            id="account-menu"
            open={Boolean(actionsMenuEl)}
            onClose={handleActionsMenuClose}
            onClick={handleActionsMenuClose}
          >
            {canUserSomeAction({
              actions: ["ARCHIVE_TAGS"],
              resource: "tags"
            }) && (
              <MenuItem
                disabled={rowSelectionModel?.length === 0}
                onClick={() => handleArchive(true)}
              >
                {t("ROOSTER.JOB-APPLICATIONS.ARCHIVE", {
                  defaultValue: "Archive"
                })}
              </MenuItem>
            )}
            {canUserSomeAction({
              actions: ["UNARCHIVE_TAGS"],
              resource: "tags"
            }) && (
              <MenuItem
                disabled={rowSelectionModel?.length === 0}
                onClick={() => handleArchive(false)}
              >
                {t("ROOSTER.JOB-APPLICATIONS.UNARCHIVE", {
                  defaultValue: "Unarchive"
                })}
              </MenuItem>
            )}
          </Menu>

          {canUserSomeAction({
            actions: ["ADD_TAGS"],
            resource: "tags"
          }) && (
            <Button
              color="primary"
              size="small"
              onClick={onCreateNew}
              variant={"outlined"}
            >
              <AddCircleIcon />{" "}
              <Typography sx={{ ml: 1 }}>
                {t("ROOSTER.COMMON.NEW-TAG", {
                  defaultValue: "New Tag"
                })}
              </Typography>
            </Button>
          )}
        </Box>
      </Box>
      <Box>
        <Stack direction="row" justifyContent="space-between">
          <TextField
            label={t("ROOSTER.COMMON.SEARCH_MIN_2", {
              defaultValue: "Search (type at least 2 chars)"
            })}
            sx={{ minWidth: 300 }}
            variant="outlined"
            size={"small"}
            margin="normal"
            value={filterParams?.searchTerm}
            InputProps={{
              endAdornment: (
                <IconButton
                  sx={{
                    visibility: filterParams?.searchTerm ? "visible" : "hidden"
                  }}
                  onClick={handleClearClick}
                >
                  <ClearIcon sx={{ fontSize: 13 }} />
                </IconButton>
              )
            }}
            onChange={handleSearch}
          />
          <Box>
            {/*  <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Autocomplete
                disablePortal
                disabled
                onChange={(_, newValue) => {
                  setFilterParams({
                    ...filterParams,
                    type: [newValue?.id] as string[]
                  });
                }}
                options={
                  [{ label: "Interviewer", id: "INTERVIEWER" }] as {
                    label: string;
                    id: string;
                  }[]
                }
                size={"small"}
                id="type-filter"
                sx={{ width: 200 }}
                renderInput={params => <TextField {...params} label="Type" />}
              />
            </FormControl> */}
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Autocomplete
                disablePortal
                value={
                  filterParams.archived === null
                    ? null
                    : filterParams.archived
                      ? { label: "Yes", id: true }
                      : { label: "No", id: false }
                }
                onChange={(_, newValue) => {
                  setFilterParams({
                    ...filterParams,
                    archived: newValue ? newValue?.id : null
                  });
                }}
                options={
                  [
                    { label: "Yes", id: true },
                    { label: "No", id: false }
                  ] as {
                    label: string;
                    id: boolean;
                  }[]
                }
                size={"small"}
                id="type-filter"
                sx={{ width: 200 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${t("ROOSTER.JOB-APPLICATION.ARCHIVED", {
                      defaultValue: "Archived"
                    })}?`}
                  />
                )}
              />
            </FormControl>
          </Box>
        </Stack>

        <div style={{ height: "calc(100vh - 220px)", width: "100%" }}>
          <DataGridPro
            checkboxSelection
            rows={data?.tags?.tags || []}
            columns={columns}
            loading={loading || deleteProcessing}
            density="compact"
            pageSizeOptions={[5, 10, 25]}
            pagination
            paginationMode="server"
            filterMode="server"
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            paginationModel={{
              pageSize: filterParams.perPage,
              page: filterParams.page > 1 ? filterParams.page - 1 : 0
            }}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            keepNonExistentRowsSelected
            onPaginationModelChange={(e) => {
              setFilterParams({
                ...filterParams,
                page: e.page + 1,
                perPage: e.pageSize
              });
            }}
            disableRowSelectionOnClick
            onRowClick={handleRowClick}
            rowCount={data?.tags?.count || 0}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 25, page: 0 }
              }
            }}
          />
        </div>
        <Drawer
          transitionDuration={0}
          anchor={"right"}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          {selectedTag && (
            <TagEdit
              afterSave={() => setDrawerOpen(false)}
              selectedTag={selectedTag as Tag}
            />
          )}
        </Drawer>
      </Box>
    </Box>
  );
}

export default TagsList;
