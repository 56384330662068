import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography
} from "@mui/material";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import * as ROUTES from "../../../constants/routes";

interface NotAllowedHereCardProps {
  title?: string;
  message?: string;
  actionName?: string;
  onAction?: () => void;
}

function NotAllowedHereCard(props: NotAllowedHereCardProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    title = t("ROOSTER.COMMON.ACCESS-DENIED", {
      defaultValue: "Access Denied!"
    }),
    message = t("ROOSTER.COMMON.NOT-ALLOWED-HERE-CARD-MESSAGE", {
      defaultValue:
        "Sorry, you do not have permission to access this page. Please click the button below to navigate to the dashboard page, where you can access authorized features and information."
    }),
    actionName = t("ROOSTER.COMMON.DASHBOARD", {
      defaultValue: "Dashboard"
    }),
    onAction
  } = props;

  const onActionButton = () => {
    if (onAction) {
      onAction();
      return;
    }
    history.push(ROUTES.JOBS);
  };

  return (
    <Box
      data-tut="application"
      sx={{
        color: "#1b74bc",
        fontSize: "16px",
        padding: "20px",
        minHeight: "calc(100vh - 70px)",
        background: theme => theme.palette.background.default
      }}
    >
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Card sx={{ maxWidth: 345 }}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {message}
            </Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: "right" }}>
            <Button size="small" onClick={onActionButton}>
              {actionName}
            </Button>
          </CardActions>
        </Card>
      </Stack>
    </Box>
  );
}

export default NotAllowedHereCard;
