import { Box, Typography } from "@mui/material";
import type React from "react";
import { useMemo } from "react";

interface DiffProps {
  diff: Record<string, any>;
  collection?: string;
}

const isNullLike = (value: unknown): boolean =>
  value === null ||
  (Array.isArray(value) && value.length === 0) ||
  (typeof value === "object" &&
    value !== null &&
    Object.keys(value).length === 0);

const renderValue = (value: unknown) => (
  <Typography>
    {Array.isArray(value) ? value.join(", ") : String(value)}
  </Typography>
);

const renderDiff = (key: string, from: unknown, to: unknown) => {
  const fromIsNullLike = isNullLike(from);
  const toIsNullLike = isNullLike(to);

  const renderBox = (color: string, symbol: string, value: unknown) => (
    <Box
      key={key}
      sx={{
        display: "flex",
        alignItems: "center",
        marginBottom: "8px"
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{ fontWeight: "bold", marginRight: "8px" }}
      >
        {key}:
      </Typography>
      <Typography sx={{ color, marginRight: "4px" }}>{symbol}</Typography>
      <Typography sx={{ color }}>{renderValue(value)}</Typography>
    </Box>
  );

  if (fromIsNullLike && !toIsNullLike) {
    return renderBox("green", "+", to);
  }

  if (!fromIsNullLike && toIsNullLike) {
    return renderBox("red", "-", from);
  }

  if (from !== to) {
    return (
      <Box
        key={key}
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "8px"
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ fontWeight: "bold", marginRight: "8px" }}
        >
          {key}:
        </Typography>
        <Typography
          sx={{
            color: "red",
            textDecoration: "line-through",
            marginRight: "4px"
          }}
        >
          {renderValue(from)}
        </Typography>
        <Typography sx={{ color: "green", marginRight: "4px" }}>→</Typography>
        <Typography sx={{ color: "green" }}>{renderValue(to)}</Typography>
      </Box>
    );
  }

  return null;
};

const OverridesDiffRenderer = ({ from, to }) => {
  const { removedItems, addedItems } = useMemo(() => {
    const toSet = new Set(to.map((item) => JSON.stringify(item)));
    const fromSet = new Set(from.map((item) => JSON.stringify(item)));

    const removedItems = from.filter(
      (item) => !toSet.has(JSON.stringify(item))
    );
    const addedItems = to.filter((item) => !fromSet.has(JSON.stringify(item)));

    return { removedItems, addedItems };
  }, [from, to]);

  const renderItems = (items, color, label) =>
    items.map((item, index) => (
      <Box key={`${label}-${index}`} sx={{ marginBottom: "8px" }}>
        <Typography sx={{ color, marginBottom: "4px" }}>{label}:</Typography>
        <Box sx={{ marginLeft: "16px" }}>
          {Object.entries(item).map(([key, value]) => (
            <Typography key={key} sx={{ color }}>
              {key}:{" "}
              {Array.isArray(value) ? `${value.join(", ")}` : `"${value}"`}
            </Typography>
          ))}
        </Box>
      </Box>
    ));

  return (
    <Box key="diff">
      <Box sx={{ marginLeft: "16px" }}>
        {renderItems(removedItems, "red", "Removed")}
        {renderItems(addedItems, "green", "Added")}
      </Box>
    </Box>
  );
};

const RedactionDiffRender: React.FC<DiffProps> = ({ diff }) => {
  const renderSection = (title: string, data: Record<string, any>) => (
    <Box>
      <Typography
        variant="subtitle2"
        sx={{ fontWeight: "bold", marginBottom: "4px" }}
      >
        {title}:
      </Typography>
      {Object.entries(data).map(([key, value]) =>
        renderDiff(key, value?.from, value?.to)
      )}
    </Box>
  );

  return (
    <Box>
      {diff?.global?.creation &&
        renderSection("Interview (All Locations)", diff.global.creation)}
      {diff?.global?.disposition &&
        renderSection("Disposition (All Locations)", diff.global.disposition)}
      {diff?.overrides && (
        <Box>
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: "bold", marginBottom: "4px" }}
          >
            Overrides:
          </Typography>
          <OverridesDiffRenderer
            from={diff.overrides.from}
            to={diff.overrides.to}
          />
        </Box>
      )}
    </Box>
  );
};

const DiffRenderer: React.FC<DiffProps> = ({ diff, collection }) => {
  if (collection === "redactionSettings") {
    return <RedactionDiffRender diff={diff} />;
  }
  return (
    <Box>
      {Object.entries(diff).map(([key, value]) =>
        renderDiff(key, value?.from, value?.to)
      )}
    </Box>
  );
};

export default DiffRenderer;
