import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import DiffRenderer from "./entityChangeLog.diffRenderer";
import { useGetEntityChangeLogs } from "./services/hooks";

const ChangeLoggerModal = ({ open, onClose, entityName }) => {
  const {
    data: changes,
    isLoading,
    error,
    refetch
  } = useGetEntityChangeLogs(entityName);

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, refetch]);

  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState("timestamp"); // Sort field
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedAndPaginatedChanges = () => {
    if (!changes) return [];
    const sortedData = [...changes.data].sort((a, b) => {
      const aValue =
        orderBy === "timestamp"
          ? new Date(a[orderBy]).getTime()
          : String(a[orderBy]);
      const bValue =
        orderBy === "timestamp"
          ? new Date(b[orderBy]).getTime()
          : String(b[orderBy]);

      if (aValue < bValue) {
        return order === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    return sortedData.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Change Log</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">
            Failed to fetch changes: {error}
          </Typography>
        ) : (changes?.count || 0) === 0 ? (
          <Typography>No changes recorded for this entity.</Typography>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "performedBy"}
                        direction={orderBy === "performedBy" ? order : "asc"}
                        onClick={() => handleRequestSort("performedBy")}
                      >
                        Performed By
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "timestamp"}
                        direction={orderBy === "timestamp" ? order : "asc"}
                        onClick={() => handleRequestSort("timestamp")}
                      >
                        Date/Time
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "operationType"}
                        direction={orderBy === "operationType" ? order : "asc"}
                        onClick={() => handleRequestSort("operationType")}
                      >
                        Action
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Change</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedAndPaginatedChanges().map((change, index) => (
                    <TableRow key={change.id}>
                      <TableCell>{change.performedBy}</TableCell>
                      <TableCell>
                        {new Date(change.timestamp).toLocaleString()}
                      </TableCell>
                      <TableCell>{change.operationType}</TableCell>
                      <TableCell>
                        <DiffRenderer
                          diff={change.diff}
                          collection={change.collection}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={changes?.count || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeLoggerModal;
